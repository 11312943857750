import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useMemo } from "react";
import { useBlocker } from "../../contexts";

var BlockerTextWave = function BlockerTextWave(_ref) {
  var text = _ref.text;

  var _useBlocker = useBlocker(),
      visualDarkMode = _useBlocker.blocker.visualDarkMode,
      acceptAllBg = _useBlocker.bodyDesign.acceptAllBg;

  var waveIntegers = useMemo(function () {
    var numbers = [];
    text.split("").forEach(function (c) {
      return c.charCodeAt(0).toString().split("").map(Number).forEach(function (n) {
        return numbers.push(n);
      });
    }); // Remove zeros and treat as 1

    numbers = numbers.map(function (number, i) {
      return number === 0 ? 1 : number;
    }); // Remove duplicates

    numbers = numbers.map(function (number, i) {
      return i > 0 ? numbers[i - 1] === number ? false : number : number;
    }).filter(Boolean); // Correct the wave to be more nicer by removing absolute opposites

    numbers = numbers.map(function (number, i) {
      if (i === 0 || i === numbers.length - 1) {
        return number;
      }

      if (Math.abs(numbers[i - 1] - number) >= 4 && Math.abs(numbers[i + 1] - number)) {
        return false;
      }

      return number;
    }).filter(Boolean); // Make sure to hold a lot of items to fill the line

    for (var i = 0; i < 100; i++) {
      if (numbers.length > 500) {
        break;
      }

      numbers = [].concat(_toConsumableArray(numbers), _toConsumableArray(numbers));
    }

    return numbers;
  }, [text]);
  return h("div", {
    style: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "absolute",
      left: 170,
      top: 44,
      right: 20,
      height: 46,
      overflow: "hidden"
    }
  }, waveIntegers.map(function (int, index) {
    return h("div", {
      key: index,
      style: {
        height: "".concat(int * 10, "%"),
        background: visualDarkMode ? "white" : acceptAllBg,
        paddingLeft: 2,
        marginLeft: index === 0 ? 0 : 2
      }
    });
  }));
};

export { BlockerTextWave };