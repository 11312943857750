import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useRef, useState, Fragment } from "react";
import { useBlocker } from "../../contexts";
import { BlockerHeroInlineStyles, BlockerButtonsInlineStyles, DefaultBlocker, BlockerTextWave } from ".";
import { CloseIcon, Portal } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { useWaitElementInViewport } from "../../hooks";
import { useRect } from "@reach/rect";

var HeroBlocker = function HeroBlocker() {
  var blocker = useBlocker();
  var _blocker$headerDesign = blocker.headerDesign,
      fontSize = _blocker$headerDesign.fontSize,
      fontColor = _blocker$headerDesign.fontColor,
      blockerHeadline = blocker.texts.blockerHeadline,
      _blocker$blocker = blocker.blocker,
      presetId = _blocker$blocker.presetId,
      name = _blocker$blocker.name,
      visualHeroButtonText = _blocker$blocker.visualHeroButtonText,
      visualContentType = _blocker$blocker.visualContentType,
      visualThumbnail = _blocker$blocker.visualThumbnail,
      paintMode = blocker.paintMode;
  var ref = useRef();
  var rect = useRect(ref);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isBtnHover = _useState2[0],
      setBtnHover = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isDefaultBlockerVisible = _useState6[0],
      setIsDefaultBlockerVisible = _useState6[1];

  var hide = visualThumbnail.hide;
  var title = visualThumbnail.title || name;
  var audioWaveText = "".concat(visualThumbnail.title, "ThiS iS jUst ANother TEXT TO reACh minIMum length") || blockerHeadline.replace(/{{name}}/g, name); // Lazy load background image

  var isBackgroundVisible = paintMode === "instantInViewport" || useWaitElementInViewport(ref); // Automatically close on outside click

  var overlayRef = useRef();
  var defaultBlockerContainerRef = useRef();
  var handleOutsideClick = useCallback(function (e) {
    if (isDefaultBlockerVisible && [overlayRef.current, defaultBlockerContainerRef.current].indexOf(e.target) > -1) {
      setIsDefaultBlockerVisible(false);
    }
  }, [isDefaultBlockerVisible, overlayRef.current, defaultBlockerContainerRef.current]);

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      close = _getOtherOptionsFromW.bannerI18n.close;

  var hideTitle = (hide === null || hide === void 0 ? void 0 : hide.indexOf("title")) > -1;
  var showPlayButton = ["video-player", "audio-player"].indexOf(visualContentType) > -1;
  var btnAttributes = {
    onMouseEnter: function onMouseEnter() {
      return setBtnHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setBtnHover(false);
    },
    onClick: function onClick(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      setIsDefaultBlockerVisible(true);
    }
  };
  return h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWrapper(blocker), {
    ref: ref
  }), isBackgroundVisible && h("div", BlockerHeroInlineStyles.aspectRatioBackgroundWrapper(blocker), h("div", BlockerHeroInlineStyles.aspectRatioBackground(blocker))), h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWholeClickable(blocker), {
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    },
    onClick: function onClick() {
      return setIsDefaultBlockerVisible(true);
    }
  })), h("div", BlockerHeroInlineStyles.aspectRatioContainer(blocker), !hideTitle && h("div", _extends({}, BlockerHeroInlineStyles.title(blocker), {
    title: title
  }), title), visualContentType === "audio-player" && h(BlockerTextWave, {
    text: audioWaveText
  }), visualHeroButtonText && ["audio-player", "video-player"].indexOf(visualContentType) === -1 ? h("div", _extends({}, BlockerButtonsInlineStyles.hero(blocker, isBtnHover, false), btnAttributes), "\xA0\xA0", visualHeroButtonText, "\xA0\xA0") : showPlayButton && (presetId === "youtube" && visualContentType === "video-player" ? h("svg", _extends({
    width: 68,
    height: 48,
    version: "1.1",
    enableBackground: "new 0 0 595.3 420.2",
    viewBox: "0 0 595.3 420.2"
  }, btnAttributes, {
    style: {
      cursor: "pointer",
      zIndex: 9
    }
  }), h("path", {
    d: "m582.3 67.8c-6.8-25.6-17.8-47.4-43.4-54.2-46.5-12.5-241.3-13.6-241.3-13.6s-194.7 1.1-241.2 13.6c-25.6 6.8-36.5 28.5-43.4 54.2-12.5 46.4-13 142.3-13 142.3s.5 95.9 13 142.3c6.8 25.6 17.8 47.4 43.4 54.2 46.5 12.4 241.2 13.6 241.2 13.6s194.8-1.1 241.2-13.6c25.6-6.8 36.6-28.5 43.4-54.2 12.4-46.5 13-142.3 13-142.3s-.4-95.9-12.9-142.3z",
    fill: isBtnHover || isHover ? "#ff0808" : "rgb(0 0 0 / 70%)"
  }), h("path", {
    d: "m401.8 210.1-173.3-96.3v192.6",
    fill: "#fff"
  })) : h("div", _extends({}, BlockerHeroInlineStyles.playButtonWrapper(blocker, isBtnHover), btnAttributes), h("div", BlockerHeroInlineStyles.playButton(blocker, isBtnHover)))), h(Portal, {
    renderInContainer: document.body
  }, h(Fragment, null, h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerOverlay(blocker, isDefaultBlockerVisible), {
    ref: overlayRef,
    onClick: handleOutsideClick
  })), h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerContainer(blocker, isDefaultBlockerVisible, rect), {
    ref: defaultBlockerContainerRef,
    onClick: handleOutsideClick
  }), h(DefaultBlocker, {
    closeIcon: h(CloseIcon, {
      tooltipText: close,
      width: fontSize,
      color: fontColor,
      thickness: 2,
      onClick: function onClick() {
        return setIsDefaultBlockerVisible(false);
      }
    })
  }))))));
};

export { HeroBlocker };