import { useBlocker } from "../../contexts";
import { DefaultBlocker, HeroBlocker } from ".";

var Blocker = function Blocker() {
  var blocker = useBlocker();
  var _blocker$blocker = blocker.blocker,
      visualType = _blocker$blocker.visualType,
      visualThumbnail = _blocker$blocker.visualThumbnail;

  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    return visualType === "hero" && visualThumbnail ? h(HeroBlocker, null) : h(DefaultBlocker, null);
    /* onlypro:end */
  } else {
    return h(DefaultBlocker, null);
  }
};

export { Blocker };